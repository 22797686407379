import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  en: {
    translation: {
      dpa: "DPA",
      eula_portal: "EULA Portal",
      eula_app: "EULA App",
      pp_clinic: "Privacy Policy Clinic",
      compareWith: "Compare with",
      rowsPerPage: "Rows per page",
      batch_details: "Batch details",
      sampling_results: "Sampling results",
      review_results: "Review results",
      barcode: "Barcode",
      QC_review: "QC review",
      QC_approved: "QC approved",
      Released: "Released",
      log: {
        login: "Log in",
        log_out: "Log Out",
      },
      landing_page: {
        login: "Log in",
        register_clinic: "Register new clinic",
        forget_password: "Forgot your password?",
        see_api_docs: "See Api documentation",
        faq: "FAQ",
      },
      required: "Required",
      delete_user: "Delete User",
      employee: "Employee",
      between: "Between",
      delete_success: "Deleted successfully",
      empty_result: "No results for the selected search criteria",
      page_not_found: "Page not found",
      welcome_message: "Welcome to Lyfstone portal",
      your_question: "Your question",
      contact_form_submitted: "Thank you, we will reply as soon as possible",
      roles: {
        ADMINISTRATOR: "ADMINISTRATOR",
        CLINIC_ADMINISTRATOR: "CLINIC ADMINISTRATOR",
        CLINIC_LAB: "CLINIC LAB",
        BEDSIDE: "BEDSIDE",
        QUALITY_CONTROL: "QUALITY CONTROL",
      },
      select_field: {
        language: "Language",
        clinic: "Clinic",
        country: "Country",
        physician: "Treating physician",
        frequency_type: "Frequency type",
        organization: "Organization",
        no_option: "Not found",
      },
      buttons: {
        create: "Create",
        cancel: "Cancel",
        next: "Next",
        back: "Back",
        finish: "Finish",
        home: "Back to home page",
        search: "Search",
        edit: "Edit",
        export_results: "Export results to Excel",
        export_filtered_results: "Export filtered results to Excel",
        export_filtered_patients: "Export filtered patients to Excel",
        details: "Details",
        update: "Update",
        delete: "Delete",
        close: "Close",
        send: "Send",
        save: "Save",
        accept: "Accept",
        reject: "Reject",
        reset_password: "Reset password",
        confirm: "Confirm",
        reset_mfa: "Reset MFA for user",
        export_all_results_to_pdf: "Export all results to PDF",
        export_this_result_to_pdf: "Export this result to PDF",
      },
      fields: {
        search: "Search",
        clinic: "Clinic",
        status: "Status",
        role: "Role",
        language: "Language",
      },
      table: {
        empty_result: "No results for the selected search criteria",
        error_message: "An error occurred. Can't display data",
      },
      statuses: {
        active: "Active",
        inactive: "Inactive",
        created: "Created",
      },
      menu_labels: {
        clinics: "Clinics",
        organizations: "Organisations",
        staff_users: "Staff Users",
        patients: "Patients",
        manage_patients: "Manage Patients",
        results: "Test Results",
        pending_results: "Pending Results",
        batches: "Batches",
        documents: "Documents",
      },
      reset_password: {
        title: "Reset your password",
        message:
          "Forgotten your password? Enter your e-mail address below, and we'll e-mail instructions for setting a new one:",
        success_title: "Success",
        error_message:
          "We are experiencing an internal server error. Please contact the administrator.",
        success:
          "If the email you provided is correct, the password reset link has been sent to your email.\nPlease check your inbox.",
      },
      managePatients_labels: {
        create_patient_button: "Create patient",
        page_title: "Manage patients",
        patient_type: "Patient type",
        patient_types: {
          home: "Home monitoring patient",
          lab: "Lab patient",
        },
        columns: {
          id: "Lyfstone Id",
          externalId: "Id",
          email: "Email",
          firstName: "First name",
          lastName: "Last name",
          treatmentType: "Patient type",
          created: "Created",
          active: "Status",
          clinicName: "Clinic",
          details: "",
        },
      },
      manageClinics_labels: {
        create_clinic_button: "Create clinic",
        page_title: "Manage clinics",
        columns: {
          name: "Name",
          city: "City",
          countryCode: "Country",
          status: "Status",
          organizationName: "Organization",
          created: "Created",
          details: "",
        },
      },
      managePendingResults_labels: {
        page_title: "<< FILL ME IN en.js FILE >>",
        columns: "FILL ME IN en.js FILE",
      },
      manageOrganizations_labels: {
        create_organization_button: "Create organization",
        page_title: "Manage organizations",
        columns: {
          name: "Name",
          moderateThreshold: "Moderate threshold",
          severeThreshold: "High threshold",
          active: "Status",
        },
      },
      manageBatches_labels: {
        create_batch_button: "Create batch",
        page_title: "Manage batches",
        releaseStatus: "Release status",
        columns: {
          number: "Batch no",
          expirationDate: "Expiry date",
          creationDate: "Created",
          status: "Release status",
          details: "",
        },
      },
      manageResults_labels: {
        page_title: "Manage results",
        classification: "Classification",
        result_type: "Result type",
        pendingResultLabel: "Pending Results",
        pendingResultTooltip:
          '"Accepted" is an accepted Pending Result "Rejected" is a rejected Pending Result',
        pendingResultTypes: {
          accepted: "Accepted",
          rejected: "Rejected",
        },
        result_types: {
          home: "Home",
          lab: "Lab",
        },
        columns: {
          date: "Date",
          externalPatientId: "Patient Id",
          systemId: "System Id",
          sampleId: "Sample Id",
          patientInfo: "Patient",
          clinicName: "Clinic",
          batchNumber: "Batch",
          finalConcentration: "Calprotection (mg/L)",
          overallResult: "Classification",
          archived: "Archived",
          labResult: "Result type",
          details: "",
        },
      },
      managePendingResults_labels: {
        page_title: "Manage pending results",
        classification: "Classification",
        columns: {
          date: "Date",
          externalPatientId: "Patient Id",
          patientInfo: "Patient",
          runNo: "Run No",
          finalConcentration: "Calprotection (mg/L)",
          overallResult: "Classification",
          actions: "Actions",
        },
      },
      signup_labels: {
        user_tab: "Personal information",
        clinic_tab: "Clinic information",
        summary_tab: "Summary",
        first_name: "First name",
        last_name: "Last name",
        email: "Email",
        password: "Password",
        confirm_password: "Confirm password",
        company: "Company",
        address1: "Address line 1",
        address2: "Address line 2",
        city: "City",
        postcode: "Postcode",
        country: "Country",
        contact_name: "Contact name",
        contact_email: "Contact email",
        contact_tel: "Telephone number",
        captcha: "Captcha",
        provide_contact_info: "Contact person for the clinic",
        provide_contact_info_subtitle:
          "This person will serve as a Clinic Administrator.",
        provide_clinic_info: "Provide information about the clinic",
        confirm_info: "Verify that the given information is correct",
        done: "Thank you for registering your clinic. A Lyfstone Administrator will review your registration and activate your account and clinic within 24 hours. You will receive an email when your account and clinic is ready to be used.",
        error_message:
          "There was a problem creating your account - please contact support@lyfstone.no",
      },
      patient_details: {
        page_title: "Manage Patient",
        first_name: "First name",
        last_name: "Last name",
        address1: "Address line 1",
        address2: "Address line 2",
        postcode: "Postcode",
        city: "City",
        country: "Country",
        email: "Email address",
        phone1: "Primary phone",
        phone2: "Secondary phone",
        patient_id: "Patient ID",
        sex: "Sex",
        date_of_birth: "Date of birth",
        clinic: "Clinic",
        physician: "Treating physician",
        frequency: "Frequency",
        frequency_type: "Frequency type",
        language: "Language",
        home_monitoring: "Patient active for home monitoring",
        interval_day: "day(s)",
        interval_week: "week(s)",
        interval_month: "month(s)",
        interval_year: "year(s)",
        moderate_threshold: "Moderate threshold",
        high_threshold: "High threshold",
        sex_male: "male",
        sex_female: "female",
        thirdPartyConsent: "Consent to third party data sharing",
        is_active: "Is active",
        patientCreated: "Patient created",
        patientUpdated: "Patient updated",
        mfa: "Multi factor authentication (MFA)",
        test_frequency_every: "Test frequency every:",
        help_message: "Indicates how often the patient should perform a test",
      },
      staff_users: {
        page_title: "Manage staff users",
        id: "Lyfstone Id",
        email: "Email",
        firstName: "First name",
        lastName: "Last name",
        active: "Active",
        edit: "Edit",
        details: "Details",
        primaryPhone: "Primary phone",
        secondaryPhone: "Secondary phone",
        roles: "Role",
        clinicName: "Clinic",
        create: "Create staff user",
      },
      user_details: {
        page_title: "Create staff user",
        first_name: "First Name",
        last_name: "Last Name",
        address1: "Address line 1",
        address2: "Address line 2",
        postcode: "Postcode",
        city: "City",
        email: "Email address",
        phone1: "Primary phone",
        phone2: "Secondary phone",
        language: "Language",
        clinic: "Clinic",
        is_active: "Is active",
        calpro_admin: "Lyfstone_Admin",
        calpro_qc: "IsLyfstone_Qc",
        clinic_admin: "Clinic_Admin",
        clinic_lab: "Clinic_Lab",
        clinic_user: "Clinic_User",
        clinic_user_all_results: "Clinic_User_All_Results",
        roles: "Roles",
        no_roles: "No roles selected, please select at least one role",
        success: "User created successfully",
        employeeCreated: "Employee created",
        employeeUpdated: "Employee updated",
        warning_title: "Warning",
        deactivation_warning:
          "You have deactivated a staff user. If they had any patients, remember to reallocate them!",
        mfa: "Multi factor authentication (MFA)",
      },
      clinic_details: {
        page_title: "Create or edit clinic",
        name: "Clinic name",
        address1: "Address line 1",
        address2: "Address line 2",
        postcode: "Postcode",
        city: "City",
        contactName: "Contact name",
        contactTel: "Contact phone",
        contactEmail: "Contact email",
        status: "Status",
        update_ok: "Clinic updated successfully",
        clinicCreated:
          "Congratulations, you have successfully created new clinic! Go to the Manage Users tab to assign a new administrator to it.",
        clinicUpdated: "Clinic updated",
        mfa_patient: "Patient Multi factor authentication (MFA)",
        mfa_staff: "Staff User Multi factor authentication (MFA)",
      },
      organization_details: {
        page_title: "Organization details",
        name: "Name",
        logo: "Logo",
        moderateThreshold: "Moderate threshold",
        severeThreshold: "High threshold",
        update_success: "Organization updated successfully",
        create_success: "Organization created successfully",
        logo_placeholder: "Insert your logo here",
        thirdPartyConsentRequired:
          "Requires patients to consent to third party data sharing",
        fullPatientInformationRequired:
          "Requires full patient information (name, address, etc.)",
        phoneRegexp: "Phone number regex",
        organizationCreated: "Organization created",
        organizationUpdated: "Organization updated",
        active: "Active",
        deactivate_organization: {
          title: "Deactivate clinic",
          message:
            "By pressing 'Confirm', you are deactivating all clinics, users and patients affiliated to this organization. If you want to keep these active, you need to affiliate these clinics to an active organization before deactivating this specific organization.",
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },
      result_basic_view: {
        concentration: "Concentration",
        classification: "Classification",
        patient: "Patient",
        patientId: "Patient Id",
        systemID: "System ID",
        sampleID: "Sample ID",
        date: "Date",
      },
      pending_result_basic_view: {
        concentration: "Concentration",
        classification: "Classification",
        patient: "Patient",
        patientId: "Patient Id",
        date: "Date",
        searchPatientPlaceholder: "Search patient by name, id or email",
        patientReassignmentSuccess: "The patient was reassigned",
        patientReassignmentFail: "Patient reassignment fail",
      },
      detailed_result_view: {
        phoneBrand: "Phone brand",
        phoneModel: "Phone model",
        phoneName: "Phone name",
        appVersion: "App version",
        readerVersion: "Reader version",
        batchNumber: "Batch number",
        batchExpDate: "Batch exp date",
        cScore: "C Score",
        tScore: "T Score",
        tcRatio: "T/C Ratio",
        cPeakPosition: "C peak position",
        tPeakPosition: "T peak position",
        cThresh: "C Threshold",
        notes: "Notes",
        image: "Image",
        stripImage: "Strip image",
        updateResultNotes: "Update result notes",
        notesCharacterLimit: "Limit 255 characters",
        notesUpdatedSuccessfully: "Notes updated successfully",
        notesUpdateFail: "Notes update fail",
      },
      result_view: {
        result: "Result",
        result_details: "Result details",
      },
      create_batch: {
        create_batch: "Create batch",
        batch_details: "Batch details",
        batch_number: "Batch number",
        manufacture_date: "Manufactured date",
        expiry_date: "Expiry date",
        moderate_threshold: "Moderate threshold",
        severe_threshold: "High threshold",
        created_by: "Created by",
        release_status: "Release status",
        barcode: "Barcode",
        calibration_equation: "Calibration parameter equation",
        calibration_parameter_a: "Calibration parameter A",
        calibration_parameter_b: "Calibration parameter B",
        calibration_parameter_c: "Calibration parameter C",
        qc_review: "QC review",
        qc_approved: "QC approved",
        released: "Released",
        reject_batch: "Reject batch",
        reject_batch_line1: "This will reject the batch and alert Lyfstone QC.",
        reject_batch_line2:
          "Before rejecting this batch you need to add a note to indicate the reason why.",
        reject_batch_line3:
          "If you do not wish to continue, click on 'Cancel' or click on 'Confirm' to reject the batch.",
        reason_label: "Reason for Rejecting",
        reject: "Reject",
        batch_created: "Batch created",
        batch_updated: "Batch updated",
        batch_failed: "Batch Operation failed",
      },
      batchResults_labels: {
        sampling_page_title: "Batch sampling results list",
        review_page_title: "Batch review results list",
        columns: {
          resultId: "ID",
          date: "Date",
          sampleId: "Sample ID",
          runNo: "Run No",
          tpeakPosition: "T peak position",
          cpeakPosition: "C peak position",
          finalScore: "Final score",
          tscore: "T score",
          cscore: "C score",
          tcRatio: "TC Ratio",
          finalConcentration: "Calprotection (mg/L)",
          overallResult: "Classification",
          details: "Details",
        },
      },
      myPage_labels: {
        page_title: "My account",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        clinicName: "Clinic",
        roles: "Roles",
        view_agreements: "View agreements you have signed",
        apiVersion: "Api version",
        releaseNotes: "Release notes",
      },
      myAgreements_labels: {
        title: "My agreements",
        version: "version",
      },
      active: {
        active: "Active",
        inactive: "Inactive",
      },
      documents_section: {
        documents: {
          publish: "Publish version",
          cannot_publish: "All translation must be finished",
        },
        versions: {
          title: "Versions",
          add_tooltip: "Add new version",
          publish: "Publish",
          release_date: "Release date",
          creation_date: "Creation date",
        },
        translations: {
          title: "Translations",
          add_tooltip: "Add new Translation",
        },
        document_representation: {
          edit: "Edit document",
        },
        edit_document_representation: {
          finish: "Finished",
          in_progress: "Save as draft",
          cancel: "cancel",
        },
        add_version: {
          title: "Add new version",
          sub_label: "Version name",
          save: "Save",
          cancel: "cancel",
          success: "Successfully added new version",
        },
        add_translation: {
          title: "Add new translation",
          sub_label: "Language name",
          save: "Save",
          cancel: "cancel",
          success: "Successfully added new translation",
        },
        tooltips: {
          none: "The same as in previous version",
          finish: "Translation finished, ready for publishing",
          in_progress:
            "Translation was updated, but is not ready for publishing",
        },
        publish: {
          title: "Publish new document version",
          notify: "Notify all users about the new version?",
          save: "Save",
          cancel: "cancel",
        },
        published: "published",
      },
      faq: {
        title: "Frequently asked questions",
        contactFormTitle: "Did not find an answer?",
        errorWhenSubmit:
          "An unexpected error has occurred, please try again later",
        questionsAndAsnswers: [
          {
            question: "I do not receive email to set passwords.",
            answer:
              "Clinic must be activated in lyfstones's system for the user to receive e-mail. It is also possible that the e-mail has ended up in spam. If you have not received an e-mail within 24 hours, please contact us at support@lyfstone.com",
          },
          {
            question: "Can I reset my user's password by myself?",
            answer:
              'Yes you can. This can be done by visiting lyfstone.com and clicking on "Forgot your password?" or directly from app "Forgot your password?"',
          },
          {
            question:
              "Error accepting agreements. I can not press Accept. What do I do?",
            answer:
              'To be able to press the "Accept" button, the user must read through the agreement, scroll down and tick "I accept". For the "Accept" button to be blue and clickable, the user must tick off all red squares.',
          },
        ],
      },
    },
  },
  pl: {
    translation: {
      dpa: "DPA",
      eula_portal: "EULA Portal",
      eula_app: "EULA App",
      pp_clinic: "Polityki Prywatności Klinik",
      compareWith: "Porównaj z",
      rowsPerPage: "Rzędów na stronie",
      batch_details: "Szczegóły partii",
      sampling_results: "WYniki pobierania próbek",
      review_results: "Sprawdź wyniki",
      barcode: "Kod kreskowy",
      QC_review: "Przegląd KJ",
      QC_approved: "Zatwierdzenie KJ",
      Released: "Wydany",
      log: {
        login: "Zaloguj się",
        log_out: "Wyloguj się",
      },
      landing_page: {
        login: "Zaloguj się",
        register_clinic: "Zarejestruj nową klinikę",
        forget_password: "Zapomniałem hasła",
        see_api_docs: "Zobacz dokumentacje Api",
        faq: "FAQ",
      },
      required: "Wymagane pole",
      delete_user: "Usuń Użytkownika",
      employee: "Pracownik",
      delete_success: "został usunięty",
      empty_result: "Brak wyników dla podanych kryteriów",
      page_not_found: "Nie znaleziono strony",
      welcome_message: "Witaj w portalu Lyfstone.",
      your_question: "Twoje pytanie",
      contact_form_submitted:
        "Dziękujemy, odpowiemy najszybciej jak to możliwe",
      roles: {
        ADMINISTRATOR: "ADMINISTRATOR",
        CLINIC_ADMINISTRATOR: "CLINIC ADMINISTRATOR",
        CLINIC_LAB: "CLINIC LAB",
        BEDSIDE: "BEDSIDE",
        QUALITY_CONTROL: "QUALITY CONTROL",
      },
      select_field: {
        language: "Język",
        clinic: "Klinika",
        country: "Kraj",
        physician: "Lekarz prowadzący",
        frequency_type: "Typ częstości",
        organization: "Organizacja",
        no_option: "Nie znaleziono",
      },
      buttons: {
        create: "Utwórz",
        cancel: "Anuluj",
        next: "Dalej",
        back: "Wstecz",
        finish: "Zakończ",
        home: "Powrót",
        search: "Wyszukaj",
        edit: "Edytuj",
        details: "Szczegóły",
        export_results: "Eksport wyników do CSV",
        export_filtered_results: "Eksport wyników do excela",
        export_filtered_patients: "Eksport pacjentów do excela",
        update: "Aktualizuj",
        delete: "Usuń",
        close: "Zamknij",
        send: "Wyślij",
        reset_password: "Zresetuj hasło",
        save: "Zapisz",
        accept: "Akceptuj",
        reject: "Odrzuć",
        confirm: "Potwierdź",
        reset_mfa: "Zresetuj MFA dla użytkownika",
        export_all_results_to_pdf: "Eksport wszystkich wyników do PDF",
        export_this_result_to_pdf: "Eksport tego wyniku do PDF",
      },
      fields: {
        search: "Wyszukaj",
        clinic: "Klinika",
        status: "Status",
        role: "Rola",
        language: "Język",
      },
      table: {
        empty_result: "Brak wyników dla podanych kryteriów",
        error_message: "Wystąpił błąd, nie można wyświetlić wyników",
      },
      statuses: {
        active: "Aktywny",
        inactive: "Nieaktywny",
        created: "Utworzony",
      },
      menu_labels: {
        clinics: "Kliniki",
        organizations: "Organizacje",
        staff_users: "Pracownicy",
        patients: "Pacjenci",
        manage_patients: "Zarządzaj pacjentami",
        results: "Wyniki testów",
        pending_results: "Oczekujące wyniki",
        batches: "Partie",
        documents: "Dokumenty",
      },
      reset_password: {
        title: "Reset hasła",
        message:
          "Straciłeś pamięć hasła? Podaj adres e-poczty a prześlemy Ci kolejne polecenia.",
        success_title: "Pomyślność",
        error_message:
          "Serwer przeżywa problemy wewnętrzne. Proszę o kontakt administratora.",
        success:
          "Jeżeli namiary są poprawne, to do twojej e-skrzynki trafiło łącze odnowy hasła.\nProszę zobaczyć.",
      },
      managePatients_labels: {
        create_patient_button: "Stwórz pacjenta",
        page_title: "Zarządzaj pacjentami",
        patient_type: "Typ pacjenta",
        patient_types: {
          home: "Pacjent na monitoring domowy",
          lab: "Pacjent laboratoryjny",
        },
        columns: {
          id: "Lyfstone Id",
          externalId: "Id",
          email: "Email",
          firstName: "Imię",
          lastName: "Nazwisko",
          treatmentType: "Typ pacjenta",
          created: "Utworzony",
          active: "Status",
          clinicName: "Klinika",
          details: "",
        },
      },
      manageClinics_labels: {
        create_clinic_button: "Stwórz klinikę",
        page_title: "Zarządzaj klinikami",
        columns: {
          name: "Nazwa",
          city: "Miejscowość",
          countryCode: "Kraj",
          status: "Status",
          organizationName: "Organizacja",
          created: "Utworzony",
          details: "",
        },
      },
      managePendingResults_labels: {
        page_title: "<< uzupełnij ten tekst w  pl.js >>",
        columns: "FILL ME IN en.js FILE",
      },
      manageOrganizations_labels: {
        create_organization_button: "Stwórz organizację",
        page_title: "Zarządzaj organizacjami",
        columns: {
          name: "Nazwa",
          moderateThreshold: "Moderate threshold",
          severeThreshold: "High threshold",
          active: "Status",
        },
      },
      manageBatches_labels: {
        create_batch_button: "Stwórz partię",
        page_title: "Zarządzaj partiami",
        releaseStatus: "Status",
        columns: {
          number: "Nr partii",
          expirationDate: "Data ważności",
          creationDate: "Utworzony",
          status: "Status",
          details: "",
        },
      },
      manageResults_labels: {
        page_title: "Zarządzaj wynikami",
        classification: "Klasyfikacja",
        result_type: "ResultType",
        pendingResultLabel: "Oczekujące Wyniki",
        pendingResultTooltip:
          '"Zaakceptowane" są to zaakceptowane Oczekujące wyniki "Odrzucone" są odrzuconymi wynikami',
        pendingResultTypes: {
          accepted: "Zaakceptowane",
          rejected: "Odrzucone",
        },
        result_types: {
          home: "Home",
          lab: "Lab",
        },
        columns: {
          date: "Data",
          externalPatientId: "Id Pacjenta",
          systemId: "System Id",
          sampleId: "Sample Id",
          patientInfo: "Pacjent",
          clinicName: "Klinika",
          batchNumber: "Partia",
          finalConcentration: "Koncentracja",
          overallResult: "Klasyfikacja",
          archived: "Zarchiwizowane",
          labResult: "Typ wyniku",
          details: "",
        },
      },
      signup_labels: {
        user_tab: "Dane osobowe",
        clinic_tab: "Informacje o klinice",
        summary_tab: "Podsumowanie",
        first_name: "Imię",
        last_name: "Nazwisko",
        email: "Email",
        password: "Hasło",
        confirm_password: "Powtórz hasło",
        company: "Firma",
        address1: "Adres",
        address2: "Adres ciąg dalszy",
        address: "Adres",
        city: "Miejscowość",
        postcode: "Kod pocztowy",
        country: "Kraj",
        contact_name: "Osoba do kontaktu",
        contact_email: "Email konatktowy",
        contact_tel: "Telefon kontaktowy",
        captcha: "captcha",
        provide_contact_info: "Osoba kontaktowa dla kliniki",
        provide_contact_info_subtitle:
          "Ta osoba będzie administratorem kliniki.",
        provide_clinic_info: "Podaj dane kliniki",
        confirm_info: "Przejżyj i potwierdź podane dane",
        done: "Dziękujemy za zarejestrowanie kliniki. Administrator Lyfstone sprawdzi twoją rejestrację i aktywuje twoje konto oraz klinikę w ciągu 24 godzin. Otrzymasz wiadomość e-mail, gdy Twoje konto i klinika będą gotowe do użycia",
        error_message:
          "Nastąpił błąd podczas tworzenia konta - proszę skontaktuj się z nami support@lyfstone.com",
      },
      patient_details: {
        page_title: "Szczegóły Pacjenta",
        first_name: "Imię",
        last_name: "Nazwisko",
        address1: "Adres linia 1",
        address2: "Adres linia 2",
        postcode: "Kod pocztowy",
        city: "Miasto",
        country: "Kraj",
        email: "Adres e-mail",
        phone1: "Numer telefonu komórkowego",
        phone2: "Numer zastępczego telefonu komórkowego",
        patient_id: "ID pacjenta",
        sex: "Płeć",
        date_of_birth: "Data urodzenia",
        clinic: "Klinika",
        physician: "Lekarz prowadzący",
        frequency: "Częstość",
        frequency_type: "Typ częstości",
        language: "Język",
        home_monitoring: "Pacjenty aktywny na monitoring domowy",
        interval_day: "dzień/dni",
        interval_week: "tydzień/tygodni",
        interval_month: "miesiąc/miesięcy",
        interval_year: "rok/lat",
        moderate_threshold: "Umiarkowany threshold",
        high_threshold: "Wysoki threshold",
        sex_male: "Mężczyzna",
        sex_female: "Kobieta",
        thirdPartyConsent:
          "Zgoda na przetwarzanie danych przez podmioty współpracujące",
        mfa: "Uwierzytelnienie wieloetapowe",
        is_active: "Aktywny",
        patientCreated: "Pacjent stworzony",
        patientUpdated: "Pacjent zaktualizowany",
      },
      staff_users: {
        page_title: "Zarządzaj użytkownikami załogi",
        id: "Lyfstone Id",
        email: "Email",
        firstName: "Imię",
        lastName: "Nazwisko",
        active: "Aktywny",
        edit: "Edituj",
        details: "Szczegóły",
        primaryPhone: "Pierszy phone",
        secondaryPhone: "Drugi phone",
        roles: "Rola",
        clinicName: "Przychodnia",
        create: "Stwórz pracownika",
      },
      user_details: {
        page_title: "Szczegóły Użytkownika",
        first_name: "Imię",
        last_name: "Nazwisko",
        address1: "Adres linia 1",
        address2: "Adres linia 2",
        postcode: "Kod pocztowy",
        city: "Miasto",
        email: "Adres e-mail",
        phone1: "Numer telefonu komórkowego",
        phone2: "Numer zastępczego telefonu komórkowego",
        mfa: "Uwierzytelnienie wieloetapowe",
        language: "Język",
        clinic: "Klinika",
        is_active: "Czy aktywny",
        calpro_admin: "Lyfstone_Admin",
        calpro_qc: "IsLyfstone_Qc",
        clinic_admin: "Clinic_Admin",
        clinic_lab: "Clinic_Lab",
        clinic_user: "Clinic_User",
        clinic_user_all_results: "Clinic_User_All_Results",
        roles: "Role",
        no_roles: "Nie wybrano żadnej roli, wybierz przynajmniej jedną",
        success: "Pracownik został utworzony z pomyślnością",
        employeeCreated: "Pracownik stworzony",
        employeeUpdated: "Pracownik zaktualizowany",
        warning_title: "Warning",
        deactivation_warning:
          "You have deactivated a staff user. If they had any patients, remember to reallocate them!",
      },
      clinic_details: {
        page_title: "Kreuj albo przemień przychodnię",
        name: "Nazwa przychodni",
        address1: "Adres linia 1",
        address2: "Adres linia 2",
        postcode: "Kod pocztowy",
        city: "Miasto",
        country: "Kraj",
        contactName: "Nazwisko kontaktu",
        contactTel: "Telefon kontaktowy",
        contactEmail: "Email do kontaktu",
        status: "Status",
        update_ok: "Klinika została uaktualniona zgodnie z poprawnością",
        clinicCreated:
          "Gratuluję! Udało Ci się wytworzyć klinikę poprawnie! Odwiedź zakładkę Użytkownicy aby dodać administratora przychodni.",
        clinicUpdated: "Klinika zaktualizowana",
        mfa_patient: "Uwierzytelnienie wieloetapowe pacjenta",
        mfa_staff: "Uwierzytelnienie wieloetapowe pracownika",
      },
      organization_details: {
        page_title: "Szczegóły organizacji",
        name: "Nazwa",
        logo: "Logo",
        moderateThreshold: "Moderate threshold",
        severeThreshold: "High threshold",
        thirdPartyConsentRequired:
          "Wymagaj akceptacji zgody na pretwarzanie od pacjentów",
        fullPatientInformationRequired:
          "Wymagaj wszystkich informacji o pacjentach",
        phoneRegexp: "Regexp dla numerów telefonu",
        organizationCreated: "Organizacja stworzona",
        organizationUpdated: "Organizacja zaktualizowana",
        active: "Aknywna",
        deactivate_organization: {
          title: "Deaktywacja kliniki",
          message:
            "Wybierając 'Potwierdź',deaktywujesz wszystkie kliniki, użytkowników i pacjentów przypisanych do tej organizacji. Jeżeli nie chcesz deaktywować którejś z klinik musisz przypisać ją do aktywnej organizacji przed deaktywacją tej organizacji.",
          cancel: "Anuluj",
          confirm: "Potwierdź",
        },
      },
      result_basic_view: {
        concentration: "Koncentracja",
        classification: "Klasyfikacja",
        patient: "Pacjent",
        patientId: "Id pacjenta",
        systemID: "System ID",
        sampleID: "Sample ID",
        date: "Data",
      },
      result_view: {
        result: "Wynik",
        result_details: "Szczegóły wyniku",
      },
      create_batch: {
        create_batch: "Stwórz partie",
        batch_details: "Szczegóły partii ",
        batch_number: "Numer partii",
        manufacture_date: "Data produkcji",
        expiry_date: "Data ważności",
        moderate_threshold: "Umiarkowany próg",
        severe_threshold: "Wysoki próg",
        created_by: "Stworzone przez",
        release_status: "Status wypuszczenia",
        barcode: "Barcode",
        calibration_equation: "Parametry równania kalibracji",
        calibration_parameter_a: "Parametr kalibracji A",
        calibration_parameter_b: "Parametr kalibracji B",
        calibration_parameter_c: "Parametr kalibracji C",
        qc_review: "QC review",
        qc_approved: "QC approved",
        released: "Wypuszczony",
        reject_batch: "Odrzucona partia",
        reject_batch_line1:
          "To spowodouje odrzucenia partii i poinformowanie Lyfstone QC.",
        reject_batch_line2: "Przed odrzuceniem partii musisz napisać powód",
        reject_batch_line3:
          "Jeśli nie chcesz kontynuować, kliknij na 'anuluj' albo na 'akceptuj' aby odrzucić partie",
        reason_label: "Powody odrzucenia",
        reject: "Odrzuć",
        batch_created: "Partia utworzona",
        batch_updated: "Partia zaktualizowana",
        batch_failed: "Operacja na partii nieudana",
      },
      detailed_result_view: {
        phoneBrand: "Firma telefonu",
        phoneModel: "Model telefonu",
        phoneName: "Nazwa telefonu",
        appVersion: "Wersja aplikacji",
        readerVersion: "wersja readera",
        batchNumber: "Numer partii",
        batchExpDate: "Data przydatności partii",
        cScore: "C Score",
        tScore: "T Score",
        tcRatio: "T/C Ratio",
        cPeakPosition: "C peak position",
        tPeakPosition: "T peak position",
        cThresh: "C Threshold",
        notes: "Notatki",
        image: "Obraz",
        stripImage: "Obraz wycięty",
        updateResultNotes: "Aktualizuj notatki o wyniku",
        notesCharacterLimit: "Limit 255 znaków",
        notesUpdatedSuccessfully: "Notatka dodana",
        notesUpdateFail: "Nie dodano notatki",
      },
      batchResults_labels: {
        sampling_page_title: "Lista wyników partii",
        review_page_title: "Lista wyników przeglądu zbiorczego",
        columns: {
          resultId: "ID",
          date: "Data",
          sampleId: "ID Próbki",
          runNo: "Numer próby",
          tpeakPosition: "Pozycja maksymalna T",
          cpeakPosition: "Pozycja maksymalna X",
          finalScore: "Wynik końcowy",
          tscore: "Punktacja T",
          cscore: "Punktacja C",
          tcRatio: "Współczynnik TC",
          finalConcentration: "Koncentracja",
          overallResult: "Klasyfikacja",
          details: "Szczegóły",
        },
      },
      active: {
        active: "Aktywny",
        inactive: "Nieaktywny",
      },
      myPage_labels: {
        page_title: "Moje konto",
        firstName: "Imię",
        lastName: "Nazwisko",
        email: "Email",
        clinicName: "Klinika",
        roles: "Role",
        view_agreements: "Pokaż podpisane zgody",
        apiVersion: "Wersja API",
        releaseNotes: "Informacje o wersjach",
      },
      documents_section: {
        documents: {
          publish: "Opublikuj versje",
          cannot_publish: "Wszystkie tłumaczenia muszą być ukończone.",
        },
        versions: {
          title: "Wersje",
          add_tooltip: "Dodaj nową wersje",
          release_date: "Data publikacji",
          creation_date: "Data utworzenia",
        },
        translations: {
          title: "Tłumaczenia",
          add_tooltip: "Dodaj nowe tłumaczenie",
        },
        document_representation: {
          edit: "Edytuj dokument",
        },
        edit_document_representation: {
          finish: "Zakończony",
          in_progress: "Zapisz jako niedokończony",
          cancel: "anuluj",
        },
        add_version: {
          title: "Dodaj nową wersje",
          sub_label: "Nazwa wersji",
          save: "Zapisz",
          cancel: "anuluj",
          success: "Dodano nową wersje",
        },
        add_translation: {
          title: "Dodaj nowe tłumaczenie",
          sub_label: "Nazwa jęzka",
          save: "Zapisz",
          cancel: "anuluj",
          success: "Dodano nowe tłumaczenie",
        },
        tooltips: {
          none: "Identyczne z poprzednią wersją",
          finish: "Tłumaczenie skończone, gotowe na publikacje",
          in_progress: "W trakcie zmian",
        },
        publish: {
          title: "Opublikuj nową wersję",
          notify: "Powiadom użytkowników o nowej wersji?",
          save: "Zapisz",
          cancel: "Anuluj",
        },
        published: "opublikowany",
      },
      faq: {
        title: "Często zadawane pytania",
        contactFormTitle: "Nie znalazłaś/eś odpowiedzi na swoje pytanie?",
        errorWhenSubmit:
          "Wystąpił nieoczekiwany błąd, proszę spróbować później",
        questionsAndAsnswers: [
          {
            question: "Nie otrzymuję e-maila do ustawiania hasła.",
            answer: 'Klinika musi być aktywowana w systemie Lyfstone, aby użytkownik mógł otrzymywać e-mail. Możliwe też, że e-mail trafił do spamu. Jeśli nie otrzymałeś wiadomości e-mail w ciągu 24 godzin, skontaktuj się z nami pod adresem support@lyfstone.com',
          },
          {
            question: "Czy mogę samodzielnie zresetować hasło użytkownika?",
            answer:
              'Tak, możesz. Można to zrobić odwiedzając lyfstone.com i klikając „Nie pamiętasz hasła?” lub bezpośrednio z aplikacji „Zapomniałeś hasła?',
          },
          {
            question:
              "Błąd podczas akceptowania umów. Nie mogę nacisnąć Akceptuj. Co robić?",
            answer:
              'Aby móc nacisnąć przycisk „Akceptuję”, użytkownik musi przeczytać umowę, przewinąć w dół i zaznaczyć „Akceptuję”. Aby przycisk „Akceptuj” był niebieski i można go było kliknąć, użytkownik musi odhaczyć wszystkie czerwone kwadraty.',
          },
        ],
      },
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: false,

    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en",
  });

export default i18n;
