import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import ClassificationComponent from "../common/ClassificationComponent";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import toastr from "toastr";
import {
  PDF_EXPORT_RESULT_SERVICE,
  RESULT_SERVICE,
} from "../../common/services/availableServices";
import HistoryGraph from "../common/Graphs/HistoryGraph";
import Utils from "../../common/Utils";
import { permission } from "../../Auth/permissions";
import PermissionContainer from "../../Auth/PermissionContainer";
import ExportPdfButton from "../../common/components/ExportPdfButton";
import { withTranslation } from 'react-i18next';
import { renderFinalScore } from '../../utils/calprotection';

const styles = (theme) => ({
  root: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
});

class PatientResultBasicView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      criteriaSpecificResult: {
        archived: false,
        specificResultId: this.props.resultId,
      },
      criteriaAllResults: {
        archived: false,
      },
    };
  }

  componentDidMount() {
    this.fetchResult(this.props.resultId);
  }

  fetchResult(id) {
    RESULT_SERVICE.get(id)
      .then((response) => {
        this.setState({
          result: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      });
  }

  deleteResult(id) {
    RESULT_SERVICE.delete(id)
      .then(() => {
        this.props.onSuccessButtonClick();
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      });
  }

  render() {
    const { classes, onCloseButtonClick, t } = this.props;
    const { result, loading } = this.state;

    if (loading) return <LoadingIndicator />;

    return (
      <React.Fragment>
        <Grid container className={classes.root}>
          <Grid container item xs={5}>
            <Grid className="summary-grid" item xs={4}>
              <Typography variant="subtitle1" paragraph={true}>
                {t('result_basic_view.concentration')}:
              </Typography>
            </Grid>
            <Grid className="summary-grid" item xs={8}>
              <Typography variant="h6" paragraph={true}>
                {renderFinalScore(result.finalScore, this.props.t('between') ,result.finalConcentration)} mg/L
              </Typography>
            </Grid>
            <Grid className="summary-grid" item xs={4}>
              <Typography variant="subtitle1" paragraph={true}>
                {t('result_basic_view.classification')}:
              </Typography>
            </Grid>
            <Grid className="summary-grid" item xs={8}>
              <ClassificationComponent classification={result.overallResult} />
            </Grid>
            <Grid className="summary-grid" item xs={4}>
              <Typography variant="subtitle1" paragraph={true}>
                {t('result_basic_view.systemID')}:
              </Typography>
            </Grid>
            <Grid className="summary-grid" item xs={8}>
              <Typography variant="subtitle1" paragraph={true}>
                  {/* change to system ID */}
                {result.systemId}
              </Typography>
            </Grid>
            <Grid className="summary-grid" item xs={4}>
              <Typography variant="subtitle1" paragraph={true}>
                {t('result_basic_view.sampleID')}:
              </Typography>
            </Grid>
            <Grid className="summary-grid" item xs={8}>
              <Typography variant="subtitle1" paragraph={true}>
                  {/* change to sample ID */}
                {result.sampleId}
              </Typography>
            </Grid>
            <Grid className="summary-grid" item xs={4}>
              <Typography variant="subtitle1" paragraph={true}>
                {t('result_basic_view.date')}:
              </Typography>
            </Grid>
            <Grid className="summary-grid" item xs={8}>
              <Typography variant="subtitle1" paragraph={true}>
                {Utils.dateRenderer(result.date)}
              </Typography>
            </Grid>
            <PermissionContainer
              allowedPermissions={[
                permission.patientResult_delete_clinic,
                permission.patientResult_delete_all,
              ]}
            >
              <Grid container>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.deleteResult(this.props.resultId)}
                  >
                    {t('buttons.delete')}
                  </Button>
                </Grid>
              </Grid>
            </PermissionContainer>

            {/* <PermissionContainer
              allowedPermissions={[
                permission.patientResult_view_all,
                permission.patientResult_view_clinic,
                permission.patientResult_view_your,
              ]}
            >
              <Grid container style={{ paddingTop: "16px" }}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <ExportPdfButton
                    service={PDF_EXPORT_RESULT_SERVICE}
                    criteria={this.state.criteriaSpecificResult}
                    patientId={result.patientId}
                    label={buttonLabels.export_this_result_to_pdf}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: "16px" }}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <ExportPdfButton
                    service={PDF_EXPORT_RESULT_SERVICE}
                    criteria={this.state.criteriaAllResults}
                    patientId={result.patientId}
                    label={buttonLabels.export_all_results_to_pdf}
                  />
                </Grid>
              </Grid>
            </PermissionContainer> */}
          </Grid>
          {/* <Grid container item xs={7}>
            <HistoryGraph patientId={result.patientId} resultId={result.id} />
          </Grid> */}
        </Grid>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            alignItems: "flex-end",
            padding: 16,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={onCloseButtonClick}
          >
            {t('buttons.close')}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(
  withStyles(styles),
  withTranslation(),
)(PatientResultBasicView);
