export const renderFinalScore = (finalScore, prefix, fallback) => {
  if (typeof finalScore === 'number') {
    if (finalScore < 14) {
      return '<14';
    }
    if (finalScore > 14 && finalScore <= 24) {
      return `${prefix} 14-24`
    }
    if (finalScore > 300) {
      return '>300';
    }
    return finalScore;
  }
  return fallback;
}